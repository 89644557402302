import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import './scss/academy-info-card.scss';
import CircledArrow from 'icons/circled-arrow.svg';
import { getFullMonthAndDay, getAcademyIcon } from 'utilities';
import { GlobalStateContext } from 'containers/GlobalContextProvider';

const AcademyInfoCard = ({
  type,
  title,
  applicationDeadline,
  admissionStartDate,
  admissionEndDate,
  duration,
  slug,
  location,
}) => {
  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  const lang = languageContext.language.id;

  return (
    <Link className="cards-row__element" to={slug}>
      <div className="academy-info-card">
        <div className="academy-info-card__content">
          <figure className="academy-info-card__icon">
            <img src={getAcademyIcon(type)} alt={`Icon - ${title}`} />
          </figure>
          <div className="academy-info-card__text">
            <h3
              className="academy-info-card__title"
              dangerouslySetInnerHTML={{
                __html: title.replace('Academy for ', 'Academy for <br/>'),
              }}
            />
            {location && (
              <p className="academy-info-card__info">
                {`${translation['academyInfo.label.location']}:`}
                <span className="academy-info-card__date">{location}</span>
              </p>
            )}
            {title !== 'Academy for Front-End' &&
              title !== 'Front-End akademija' &&
              applicationDeadline && (
                <p className="academy-info-card__info">
                  {`${translation['academyInfo.label.deadline']}:`}
                  <span className="academy-info-card__date">
                    {getFullMonthAndDay(applicationDeadline, lang)}
                  </span>
                </p>
              )}
            {title === 'Academy for Front-End' && (
              <p className="academy-info-card__info">
                {`${translation['academyInfo.label.deadline']}:`}
                <span className="academy-info-card__date">
                  Not scheduled for 2025
                </span>
              </p>
            )}
            {title === 'Front-End akademija' && (
              <p className="academy-info-card__info">
                {`${translation['academyInfo.label.deadline']}:`}
                <span className="academy-info-card__date">
                  2025 m. akademija nebus organizuojama
                </span>
              </p>
            )}
            {title !== 'Academy for Front-End' &&
              title !== 'Front-End akademija' &&
              admissionStartDate && (
                <p className="academy-info-card__info">
                  {`${translation['academyInfo.label.admission']}:`}
                  <span className="academy-info-card__date">
                    {getFullMonthAndDay(admissionStartDate, lang)}
                    {admissionEndDate &&
                      ` - ${getFullMonthAndDay(admissionEndDate, lang)}`}
                  </span>
                </p>
              )}
            {duration && (
              <p className="academy-info-card__info">
                {`${translation['academyInfo.label.duration']}:`}
                <span className="academy-info-card__date">{duration}</span>
              </p>
            )}
          </div>
        </div>
        <span className="academy-info-card__link btn-link btn-link--circled-arrow">
          {translation['button.learnMore']}
          <CircledArrow />
        </span>
      </div>
    </Link>
  );
};

AcademyInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  admissionStartDate: PropTypes.string,
  admissionEndDate: PropTypes.string,
  applicationDeadline: PropTypes.string,
  duration: PropTypes.string,
  type: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  location: PropTypes.string,
};

export { AcademyInfoCard };
